import "./Button.css";

function Button({ text, type, invert }) {
  return (
    <button className={invert ? "inverted-button" : "button"} type={type}>
      {text}
    </button>
  );
}

export default Button;
