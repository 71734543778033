import { TeamData } from "../data/TeamData.js";
import SmallCard from "../SmallCard.js";
import "./Tours.css";

function Team() {
  const teamList = TeamData.map((member) => {
    return (
      <SmallCard imgSrc={member.src} title={member.name} text={member.text} />
    );
  });

  return (
    <div>
      <h1 className="section-heading">Meet the Team</h1>
      <div className="team-container">{teamList}</div>
    </div>
  );
}

export default Team;
