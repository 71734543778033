import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./components/pages/Home";
import Sumo from "./components/pages/Sumo";
import Sumida from "./components/pages/Sumida";
import Farming from "./components/pages/Farming";
import Culture from "./components/pages/Culture";
import SumoExperience from "./components/pages/SumoExperience";
import Custom from "./components/pages/Custom";
import Pricing from "./components/pages/Pricing";
import Team from "./components/pages/Team";
import Contact from "./components/pages/Contact";
import Company from "./components/pages/Company";
import ScrollToTop from "./components/ScrollToTop";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path="/" exact Component={Home} />
          <Route path="/tours/sumo-morning" Component={Sumo} />
          <Route path="/tours/downtown-tour" Component={Sumida} />
          <Route path="/tours/farming" Component={Farming} />
          <Route path="/tours/culture" Component={Culture} />
          <Route path="/tours/sumo-experience" Component={SumoExperience} />
          <Route path="/tours/custom" Component={Custom} />
          <Route path="/pricing" Component={Pricing} />
          <Route path="/contact" Component={Contact} />
          <Route path="/team" Component={Team} />
          <Route path="/company" Component={Company} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
