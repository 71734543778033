import { Link } from "react-router-dom";
import { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { HashLink } from "react-router-hash-link";
import "./Navbar.css";

function Navbar() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  return (
    <>
      <nav className="navbar-top">
        <div className="navbar-container">
          <HashLink
            smooth
            to="/#"
            className="navbar-logo"
            onClick={closeMobileMenu}
          >
            <img src={process.env.PUBLIC_URL + "/images/logo.jpg"} alt="logo" />
          </HashLink>
          <div className="menu-icon" onClick={handleClick}>
            {click ? (
              <FaTimes className="fa-times" />
            ) : (
              <FaBars className="fa-bars" />
            )}
          </div>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <HashLink
                smooth
                to="/#"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                home
              </HashLink>
            </li>
            <li className="nav-item">
              <HashLink
                smooth
                to="/#tours"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                tours
              </HashLink>
            </li>
            <li className="nav-item">
              <HashLink
                smooth
                to="/#about"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                about
              </HashLink>
            </li>
            <li className="nav-item">
              <Link
                to="/pricing"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                pricing
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link to="/JP" className="nav-links" onClick={closeMobileMenu}>
                <FaGlobe />
                &nbsp;JP
              </Link>
            </li> */}
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
