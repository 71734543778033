const breakpoints = [4320, 2160, 1080, 640, 384, 256, 128];

const photoList = [
  {
    src: "/images/sumo/PHOTO-2023-12-03-12-14-15.jpg",
    width: 1600,
    height: 1200,
  },
  {
    src: "/images/sumo/PHOTO-2023-12-03-12-14-15_1.jpg",
    width: 1600,
    height: 1200,
  },
  {
    src: "/images/sumo/PHOTO-2023-12-03-12-14-16.jpg",
    width: 1600,
    height: 1200,
  },
  {
    src: "/images/sumo/PHOTO-2023-12-03-12-14-16_1.jpg",
    width: 1600,
    height: 1200,
  },
  {
    src: "/images/sumo/PHOTO-2023-12-03-12-14-16_2.jpg",
    width: 1600,
    height: 1200,
  },
  {
    src: "/images/sumo/PHOTO-2023-12-03-12-14-16_3.jpg",
    width: 1600,
    height: 1200,
  },
  {
    src: "/images/sumo/PHOTO-2023-12-03-12-14-16_4.jpg",
    width: 1600,
    height: 1200,
  },
  {
    src: "/images/sumo/PHOTO-2023-12-03-12-14-16_5.jpg",
    width: 1600,
    height: 1200,
  },
  {
    src: "/images/sumo/PHOTO-2023-12-03-12-14-17.jpg",
    width: 1600,
    height: 1200,
  },
  {
    src: "/images/sumo/PHOTO-2023-12-03-12-14-17_1.jpg",
    width: 1600,
    height: 1200,
  },
];

export const slides = photoList.map((photo) => {
  const width = photo.width * 4;
  const height = photo.height * 4;
  return {
    src: photo.src,
    width,
    height,
    srcSet: breakpoints.map((breakpoint) => {
      const breakpointHeight = Math.round((height / width) * breakpoint);
      return {
        src: photo.src,
        width: breakpoint,
        height: breakpointHeight,
      };
    }),
  };
});

export default slides;
