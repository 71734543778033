import Banner from "../Banner";
import SmallCard from "../SmallCard";
import Button from "../Button";
import "./Tours.css";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

function Sumo() {
  return (
    <>
      <h1 className="section-heading">Sumo Morning and River Cruise</h1>
      <div className="tourinfo-container">
        <p>
          Start your morning watching the sumo wrestlers during a training
          session, then enjoy a traditional Japanese breakfast followed by a
          boat cruise along the Sumida River.
        </p>
        <Link to="/contact">
          <Button text="Enquire Now" />
        </Link>
      </div>
      <div className="tour-container">
        {/* <HeroSection image={"/images/sumo.jpg"} /> */}
        <Banner
          title="Sumo Morning Training Session"
          text="Join the hanamichi team and witness an incredible up and close and personal sumo morning training sesion. We have VIP access to the sumo stables where you will get a glimpse of the most popular sport in Japan, see the athletes' traditional training regimen. You may witness a future champion in action. This is too exciting to miss."
          image="/images/sumo.jpg"
        />
        <Banner
          title="Tokyo Bay River Cruise"
          text="Join the Bay Cruise and take in Tokyo as you cruise down the bay. Watch the city pass you by as you relax from the comfort of your seat. Hanamachi members will make sure to show you the best of Tokyo. Get your cameras ready."
          image="/images/rivercruise.jpg"
          right
        />
      </div>
      <div className="itinerary-container">
        <h1 className="section-heading">Itinerary</h1>
        <Container>
          <p style={{ marginLeft: "3rem", marginBottom: "2rem" }}>
            8am - 2pm tour:
          </p>
          <Row className="mb-5" xs={1} sm={1} md={1} lg={3}>
            <Col className="d-flex justify-content-center">
              <SmallCard
                className="mb-5"
                imgSrc="/images/breakfast.jpg"
                title="Japanese Breakfast"
                text="Enjoy a Japanese-style breakfast"
              />
            </Col>
            <Col className="d-flex justify-content-center">
              <SmallCard
                className="mb-5"
                imgSrc="/images/sumo.jpg"
                title="Sumo stables practice viewing"
                text="Get access to the sumo stables to watch the athletes train"
              />
            </Col>
            <Col className="d-flex justify-content-center">
              <SmallCard
                className="mb-5"
                imgSrc="/images/rivercruise.jpg"
                title="River cruise"
                text="Boat cruise around Tokyo Bay"
              />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
export default Sumo;
