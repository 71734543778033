import { FaInstagram, FaFacebook, FaTiktok } from "react-icons/fa";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

function Footer() {
  return (
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand
          as={Link}
          to="/company"
          style={{ whiteSpace: "pre-wrap" }}
        >
          OFFICE11 Company Limited Hanamichi Tours
        </Navbar.Brand>
        <Nav className="me-auto">
          <Nav.Link as={Link} to="/contact">
            Contact
          </Nav.Link>
          <Nav.Link as={Link} to="/team">
            Meet the Team
          </Nav.Link>
        </Nav>
        <Nav className="me-auto">
          <Nav.Link
            as={Link}
            to="https://www.instagram.com/hanamichi_tours?utm_source=qr&igsh=aHdnM2t2bmYwb3k"
            target="_blank"
          >
            <FaInstagram className="footer-icons" />
          </Nav.Link>
          <Nav.Link
            as={Link}
            to="https://www.facebook.com/profile.php?id=100069067513239"
            target="_blank"
          >
            <FaFacebook className="footer-icons" />
          </Nav.Link>
          <Nav.Link>
            <FaTiktok className="footer-icons" />
          </Nav.Link>
        </Nav>
      </Container>
    </Navbar>
  );
}

export default Footer;
