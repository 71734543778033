import { useState } from "react";
import { currencyList } from "./data/PricingData";
import Table from "react-bootstrap/Table";

function PriceTable({ tourData, rates, ratesFetched }) {
  const [toCurrency, setToCurrency] = useState("JPY");

  const getConvertedPrice = (price) => {
    if (isNaN(price)) {
      return price;
    } else {
      const rate = rates[toCurrency];
      return Intl.NumberFormat("en-In", {
        style: "currency",
        currency: toCurrency,
      }).format(Math.round(price * rate));
    }
  };

  const priceData = tourData.data.map((tour) => {
    return (
      <tr>
        {tour.name ? <td>{tour.name}</td> : null}
        <td>{tour.description}</td>
        {tour.priceMax ? (
          <td>
            {getConvertedPrice(tour.priceMin)} -&nbsp;
            {getConvertedPrice(tour.priceMax)}
          </td>
        ) : (
          <td>{getConvertedPrice(tour.priceMin)}</td>
        )}
      </tr>
    );
  });

  const currencyData = currencyList.map((currency) => {
    return <option value={currency}>{currency}</option>;
  });

  const headerData = tourData.headers.slice(0, -1).map((header) => {
    return <th>{header}</th>;
  });

  return (
    <>
      <div>
        <Table>
          <thead>
            <tr>
              {headerData}
              <th>
                Price (per person) &nbsp;&nbsp;
                <select
                  id="toCurrency"
                  onChange={(e) => setToCurrency(e.target.value)}
                  defaultValue="JPY"
                >
                  {ratesFetched ? (
                    currencyData
                  ) : (
                    <option defaultValue>JPY</option>
                  )}
                </select>
              </th>
            </tr>
          </thead>
          <tbody>{priceData}</tbody>
        </Table>
      </div>
    </>
  );
}

export default PriceTable;
