export const TeamData = [
  {
    src: "/images/team/chinami.JPG",
    name: "Joyce-Chinami",
    text: `Born in Osaka, and now living in Tokyo, Chinami has had 20 incredible years of guiding experience for foreign tourists in Tokyo, and she is very good at it! Chinami’s knowledge of her homeland enables her to offer ”off-beaten paths" for tourists. She is a lover of cooking and eating out (especially bread!), and would love to share her experience and knowledge of the excellent restaurants in the region. Chinami is currently learning Italian and Spanish in addition to being fluent in both Japanese and English.`,
  },
  {
    src: "/images/team/cat.jpg",
    name: "Cat",
    text: "Originally from London, Cat presently lives in Seoul, South Korea. Having met Chinami at a young age and after experiencing an eye-opening experience visiting Japan, she knew that she wanted to know more about the traditions and culture of Japan then become good at Japanese. Cat has previously worked in arts and music industry. In addition, she has been teaching English to both Japanese and Korean students whilst mastering both languages, bringing a huge contribution to our team.",
  },
  {
    src: "/images/team/yoshimi.jpeg",
    name: "Yoshimi",
    text: "Yoshimi was born and lives in Tokyo and has extensive knowledge about downtown Tokyo. She is a big fan of Queen, loves going back and forth to England, and is on our finance team. She speaks both fluent in English and Japanese.",
  },
  {
    src: "/images/team/hiroshi.JPG",
    name: "Hiroshi (CEO)",
    text: "Hiroshi has spent many years working in finance and real estate and is now in the consulting business. His loves travelling on local trains in Japan, enjoys Sake (Japanese rice wine), and Japanese baseball and is happy to share his knowledge of good places to visit.",
  },
];
