import "./Banner.css";

function Banner({ title, children, image, alt, right }) {
  return (
    <div className="banner">
      {right && (
        <div className="image-column">
          <img className="banner-image" src={image} alt={alt} />
        </div>
      )}
      <div className="text-column">
        <h1 className="banner-title">{title}</h1>
        {children}
      </div>
      {!right && (
        <div className="image-column">
          <img className="banner-image" src={image} alt={alt} />
        </div>
      )}
    </div>
  );
}

export default Banner;
