// const breakpoints = [4320, 2160, 1080, 640, 384, 256, 128];

const photoList = [
  { src: "/images/downtown/IMG_0311.jpg", width: 4032, height: 3024 },
  { src: "/images/downtown/IMG_0796.jpg", width: 3024, height: 4032 },
  { src: "/images/downtown/IMG_0797.jpg", width: 3024, height: 4032 },
  { src: "/images/downtown/IMG_0798.jpg", width: 3024, height: 4032 },
  { src: "/images/downtown/IMG_0799.jpg", width: 3024, height: 4032 },
  { src: "/images/downtown/IMG_0800.jpg", width: 3024, height: 4032 },
  { src: "/images/downtown/IMG_4935.jpg", width: 3024, height: 4032 },
  { src: "/images/downtown/IMG_4934.jpg", width: 3024, height: 4032 },
  { src: "/images/downtown/IMG_4942.jpg", width: 3024, height: 4032 },
  { src: "/images/downtown/IMG_5042.JPG", width: 3024, height: 4032 },
];

export const slides = photoList.map((photo) => {
  const width = photo.width * 4;
  const height = photo.height * 4;
  return {
    src: photo.src,
    width,
    height,
    // srcSet: breakpoints.map((breakpoint) => {
    //   const breakpointHeight = Math.round((height / width) * breakpoint);
    //   return {
    //     src: photo.src,
    //     width: breakpoint,
    //     height: breakpointHeight,
    //   };
    // }),
  };
});

export default slides;
