import ContactSection from "../ContactSection";

function Contact() {
  return (
    <>
      <ContactSection />
    </>
  );
}

export default Contact;
