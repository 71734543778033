export const OfferData = [
  {
    image: "images/icons/sumologo.png",
    alt: "sumo",
    title: "Sumo Stable Tours",
    text: "Let us take you to the sumo stables to witness the wrestlers practice in action.",
  },
  {
    image: "images/icons/shoplogo.png",
    alt: "shop",
    title: "Local Merchants and Traditional Crafts",
    text: "It isn't easy to find local handmade items in Tokyo, but we can show you so many local craft shops. We are working closely with local communities in Tokyo so that we can introduce you to craftsmen shops for 'made in Japan' souvenirs and gifts. Take home the perfect gift whilst supporting our local craftsmen.",
  },
  {
    image: "images/icons/kimonologo.png",
    alt: "kimono",
    title: "Cultural Experiences",
    text: "Interested in Japanese culture? Why not try some dance lessons with our very own sensei? Have a kimono experience where you get to pick your fabric, wear the kimono and have your photo taken in a Japanese garden. Try the Japanese art of flower arrangements, Ikebana, or even experience a Japanese cooking class.  What would you like to try? Contact us and we can help make your cultural experience one to remember. ",
  },
  {
    image: "images/icons/foodlogo.png",
    alt: "food",
    title: "Local Food",
    text: "Looking for local restaurants, bars, sweets or brewery tours? Let us find you something special!",
  },
  {
    image: "images/icons/farminglogo.png",
    alt: "farm",
    title: "Farming Experience",
    text: "Day trips to explore Japan's finest organic agricultural farms, mingling with the locals, and learning the roots of traditional Japanese farming from our nearest and dearest.",
  },
  {
    image: "images/icons/customlogo.png",
    alt: "custom",
    title: "Customized Tours",
    text: "Everyone coming to Japan already has a wish list, spreadsheet, or Pinterest board of activities they wish to see and do, how about sharing those with us and letting us help you design your very own experience? We want you to take something from your experience with Japan home, even if it is just the memories of a lifetime. ",
  },
];
