import "./HeroSection.css";
import "../App.css";
import Button from "./Button";
import { HashLink } from "react-router-hash-link";

function HeroSection(props) {
  return (
    <div
      className="hero-container"
      style={{ backgroundImage: `url(${props.image})` }}
    >
      <h1>{props.text}</h1>

      {props.buttontext && (
        <div className="button-container">
          <HashLink smooth to="/#tours">
            <Button text={props.buttontext} invert />
          </HashLink>
        </div>
      )}
    </div>
  );
}

export default HeroSection;
