import CardItem from "./CardItem";
import Carousel from "react-multi-carousel";
import "./Cards.css";
import "react-multi-carousel/lib/styles.css";
import { TourData } from "./data/TourData.js";

function Cards({ id }) {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const tourList = TourData.map((tour) => {
    return (
      <CardItem
        src={tour.src}
        alt={tour.text}
        text={tour.text}
        path={tour.path}
        color="white"
        textcolor="black"
      />
    );
  });

  return (
    <div className="cards">
      <h1 id={id} className="section-heading">
        Tours
      </h1>
      <div className="carousel-container">
        <Carousel responsive={responsive} showDots={true}>
          {tourList}
        </Carousel>
      </div>
    </div>
  );
}

export default Cards;
