import Banner from "../Banner";
import Button from "../Button";
import Gallery from "../Gallery";
import slides from "../data/FarmSlides.js";
import "./Tours.css";
import { Link } from "react-router-dom";

function Farming() {
  const mainText = `Discover and learn directly from Local organic farmers in Chiba. Discover how they produce their crops by spending the day observing and learning about growing and selling rice and vegetables, traced back to the Edo Period.\n\nDepending on the season, get hands on dirty a bit, by joining the daily activities of either sowing, harvesting or maintaining the vegetables or rice. It's an experience you'll never forget.`;

  return (
    <>
      <h1 className="top-title">Farming Experience</h1>
      <div className="tourinfo-container">
        <p style={{ whiteSpace: "pre-wrap" }}>{mainText}</p>
        <Link to="/contact">
          <Button text="Enquire Now" />
        </Link>
      </div>
      <div className="tour-container">
        <Banner title="Farming Experience" image="/images/farming/IMG_2178.jpg">
          <p>
            Learn about growing and selling organic farm vegetables on a farm
            traced back to the Edo Period.
          </p>
        </Banner>
        <Banner
          title="Samurai Bamboo Forest and Samurai House"
          image="/images/farming/IMG_9714.jpg"
          right
        >
          <p>Walk the Samurai bamboo forest road and visit a sword museum.</p>
        </Banner>
      </div>
      <Gallery slides={slides} />
    </>
  );
}
export default Farming;
