import PriceTable from "../PriceTable.js";
import { useState, useEffect } from "react";
import {
  customItineraries,
  tokyoTours,
  dayTrips,
} from "../data/PricingData.js";

function Pricing() {
  const [rates, setRates] = useState({ JPY: 1 });
  const [ratesFetched, setRatesFetched] = useState(false);

  const getRates = async () => {
    // fetch the data from API
    const response = await fetch(
      "https://v6.exchangerate-api.com/v6/8ae1f5574dbcd22fbe06c767/latest/JPY"
    ).then((response) => response.json());

    if (response.result === "success") {
      setRates(response.conversion_rates);
      setRatesFetched(true);
      console.log(response.conversion_rates);
    }
  };

  useEffect(() => {
    getRates();
  }, []);

  return (
    <>
      <h1 className="section-heading">Pricing</h1>;
      <div className="mb-5 mx-3 mx-lg-5">
        <div className="pricing-info">
          <p>
            We are more than happy to help you enjoy your time in Japan, and can
            provide a flexible itinerary to improvise or can provide a detailed
            itinerary with additional information such as tricky bookings such
            as some museums, trains, sumo tickets, restaurants and much more! We
            also have some discounts for families and a large group, so please
            talk to us!
          </p>
          <p>These prices do not include the following:</p>
          <ul>
            <li>
              Transportation expenses
              <ul>
                <li>Estimated at 800-1,200 JPY pp.</li>
                <li>
                  Travel cards for foreign tourists are the best option and we
                  can help you purchase one if you join our introductory tour!
                </li>
              </ul>
            </li>
            <li>
              Lunch for you and your guide.
              <ul>
                <li>
                  Lunch costs about 1,500 - 2,500 JPY pp. (A sushi lunch will be
                  slightly more expensive!)
                </li>
                <li>
                  We can help you find one to suit your budget, so please let us
                  know!
                </li>
              </ul>
            </li>
            <li>Admission fees such as museums/temples etc.</li>
            <li>Drinks and snacks</li>
          </ul>
        </div>
        <div className="my-5">
          <h2>Custom Itineraries</h2>
          <div className="my-3 mx-3 mx-lg-5">
            <PriceTable
              tourData={customItineraries}
              rates={rates}
              ratesFetched={ratesFetched}
            />
          </div>
        </div>
        <div className="my-5">
          <h2>Guided Tours</h2>
          <p>
            The price depends on the means of transportation, duration, and an
            available guide, so please talk to us and we can work it out on your
            preferences or budget!
          </p>
          <div className="my-3 mx-3 mx-lg-5">
            <div className="my-5">
              <h3>Tokyo Tours</h3>
              <PriceTable
                tourData={tokyoTours}
                rates={rates}
                ratesFetched={ratesFetched}
              />
            </div>
            <div className="mb-5">
              <h3>Day Trips</h3>
              <PriceTable
                tourData={dayTrips}
                rates={rates}
                ratesFetched={ratesFetched}
              />
            </div>
            <h3>Overnight Tours</h3>
            <p>Hakone/Nikko/Kyoto etc. (Negotiable)</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Pricing;
