import { useState } from "react";
import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";
import Button from "./Button.js";
import Form from "react-bootstrap/Form";
import "./ContactSection.css";

function ContactForm() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [consult, setConsult] = useState("yes");

  const submitMail = (data, e) => {
    e.preventDefault();

    const publicKey = process.env.REACT_APP_PUBLIC_KEY;
    const serviceID = process.env.REACT_APP_SERVICE_ID;
    const templateID = process.env.REACT_APP_TEMPLATE_ID;

    const templateParams = {
      name: name,
      email: email,
      message: message,
      consult: consult,
    };

    emailjs
      .send(serviceID, templateID, templateParams, publicKey)
      .then((response) => {
        console.log("Message Sent", response.text);
        alert("Message Sent");
        setName("");
        setEmail("");
        setMessage("");
        setConsult("yes");
      })
      .catch((error) => {
        console.error("error sending email", error);
      });
  };

  return (
    <div>
      <Form onSubmit={handleSubmit(submitMail)}>
        <Form.Group className="mb-4">
          <Form.Label className="form-label" htmlFor="name">
            Your name
          </Form.Label>
          <Form.Control
            type="text"
            id="name"
            {...register("name", { required: "Name is required" })}
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          {errors.name?.message && <small>{errors.name.message}</small>}
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label className="form-label" htmlFor="email">
            Your email
          </Form.Label>
          <Form.Control
            type="email"
            id="email"
            {...register("email", {
              required: "Email is required",
              validate: {
                matchPattern: (v) =>
                  /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                  "Email address must be a valid address",
              },
            })}
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {errors.email?.message && <small>{errors.email.message}</small>}
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label className="form-label" htmlFor="message">
            Your message
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={5}
            id="message"
            {...register("message", { required: "Message is required" })}
            name="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          {errors.message?.message && <small>{errors.message.message}</small>}
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label className="form-label" htmlFor="consult">
            Would you like a free consultation?
          </Form.Label>
          <Form.Label style={{ fontSize: "1rem" }}>
            if YES we will send you a quick questionnaire to complete before the
            call!
          </Form.Label>
          <Form.Check
            type="radio"
            label="Yes"
            name="radioGroup"
            id="yes"
            value="yes"
            checked
            onChange={(e) => setConsult(e.target.value)}
          ></Form.Check>
          <Form.Check
            type="radio"
            label="No"
            name="radioGroup"
            value="no"
            id="no"
            onChange={(e) => setConsult(e.target.value)}
          ></Form.Check>
        </Form.Group>
        <Button type="submit" text="Send" />
      </Form>
    </div>
  );
}

export default ContactForm;
