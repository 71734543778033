import Banner from "../Banner";
import Button from "../Button";
import Gallery from "../Gallery";
import "./Tours.css";
import { Link } from "react-router-dom";
import slides from "../data/SumoSlides.js";

function SumoExperience() {
  return (
    <>
      <h1 className="section-heading">
        Sumo Educational Tour and Sumo Tournament
      </h1>
      <div className="tourinfo-container">
        <p>
          You can join us for an educational and fun event where you'll learn
          about the rules, techniques and history of a tournament day. You get
          to meet a few former Sumo Wrestlers, can ask questions and take some
          photos! We can also obtain some sumo tournament tickets (subject to
          availability when the tournaments are on.) Alternatively, if you are
          on a budget, we can take you to a local Sumo stable to watch from the
          outside and then head off to a tournament.
        </p>
        <Link to="/contact">
          <Button text="Enquire Now" />
        </Link>
      </div>
      <div className="tour-container">
        <Banner
          title="Sumo Tournaments"
          image="/images/sumo/PHOTO-2023-12-03-12-14-17.jpg"
        >
          <p>There are six Sumo tournaments throughout a year in Japan.</p>
          <ul>
            <li>January, May and September → Tokyo</li>
            <li>March → Osaka</li>
            <li>July → Nagoya</li>
            <li>November → Fukuoka</li>
          </ul>
          <p>
            Subject to availability, we could possibly get tickets on your
            behalf, so please let us know!
          </p>
        </Banner>
      </div>
      <Gallery slides={slides} />
    </>
  );
}
export default SumoExperience;
