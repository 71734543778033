import Banner from "../Banner";
import Button from "../Button";
import Popup from "../Popup";
import "./Tours.css";
import { Link } from "react-router-dom";

function Culture() {
  return (
    <>
      <h1 className="section-heading">Cultural Experience</h1>
      <div className="tourinfo-container">
        <p>
          Interested in Japanese culture? Why not try some Japanese dance
          lessons with our very own sensei? Have a kimono experience, wear a
          kimono and you can have your photo taken in a Japanese garden. Try the
          Japanese art of flower arrangements, Ikebana, or even experience a
          Japanese cooking class. What would you like to try? Contact us and we
          can help make your cultural experience one to remember.
        </p>
        <Link to="/contact">
          <Button text="Enquire Now" />
        </Link>
      </div>
      <div className="tour-container">
        {/* <HeroSection image={"/images/sumo.jpg"} /> */}
        <Banner
          title="Japanese Dance Lessons"
          image="/images/culture/IMG_4544.jpg"
        >
          <div>
            <Popup
              text="Why not try and see a very traditional Japanese dance lesson? You
              can put an easy kimono, And try to mimic our sensei (a teacher)
              with proper Japanese greetings, Japanese expressions, movements or
              poses. We can also offer visiting a local merchant, then ended up
              visiting Tokyo Sky Tree. For more details, please click "
              linkText="HERE"
              image="/images/sumidaprice.png"
              alt="sumida tour"
            />
          </div>
        </Banner>
        <Banner
          title="Kimono Fitting"
          image="/images/culture/IMG_0132.jpg"
          right
        >
          <p>
            Experience the incredible kimono fitting, choose your fabric and
            enjoy being wrapped in Japanese traditions. Take a walk in the
            exquisite Japanese garden and allow us to capture this moment for
            you.
          </p>
        </Banner>
        <Banner
          title="Japanese Garden | Tea Ceremony"
          image="/images/culture/IMG_3742.jpg"
        >
          <p>
            Experience a ceremonial way of preparing and drinking thick/bitter
            green tea, in the true traditional Japanese style. The tradition of
            the Japanese tea ceremony is linked to Buddhism, dating back to the
            9th century, and it is a wonderful experience. We cannot wait to
            share it with you.
          </p>
        </Banner>
      </div>
    </>
  );
}
export default Culture;
