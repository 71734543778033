export const currencyList = ["JPY", "USD", "GBP", "EUR", "AUD"];

export const customItineraries = {
  headers: ["Tier", "Description", "Price"],
  data: [
    {
      name: "Tier 1",
      description: "Free consultation on zoom for 30min.",
      priceMin: "Free",
    },
    {
      name: "Tier 2",
      description:
        "Curated flexible itinerary for your trip to Japan (including weekly trips or longer).",
      priceMin: 3500,
    },
    {
      name: "Tier 3",
      description:
        "More detailed itinerary, guided tours, plus additional bookings for museums, trains, sumo events and much more.",
      priceMin: 7500,
    },
  ],
};

export const tokyoTours = {
  headers: ["Tour", "Price (per person)"],
  data: [
    {
      description: "2-3 hours (Introductory tour or Tokyo highlights).",
      priceMin: 10000,
      priceMax: 15000,
    },
    {
      description: "4-6 hours (Our own tour or customized tour)",
      priceMin: 15000,
      priceMax: 30000,
    },
    {
      description: "6-8 hours (Customized tour)",
      priceMin: 25000,
      priceMax: 45000,
    },
  ],
};

export const dayTrips = {
  headers: ["Destination", "Price (per person)"],
  data: [
    {
      description: "Yokohama/Kamakura",
      priceMin: 25000,
      priceMax: 45000,
    },
    {
      description: "Kawagoe/Chiba",
      priceMin: 25000,
      priceMax: 45000,
    },
    {
      description: "Nikko/Hakone/Kyoto",
      priceMin: 25000,
      priceMax: 60000,
    },
  ],
};
