export const InfoData = {
  heading: "About Us",
  paragraph:
    "A newly registered team of four, running exciting tours through Japan. Each of us has a unique background that brings exciting guiding experiences for visiting tourists, and we have a lot to offer!\n\nWith experienced guides who speak fluent English and Japanese (or possibly other languages upon request), our tours range from seasonal attraction tours to casual walking cultural tours, ranging from affordable adventures or that little bit more glamorous experience.\n\nWe can provide extra support on your trip from hotel bookings, taxis, travel and rail passes, luggage couriers, restaurants, Sumo and Ghibli Museum tickets (subject to availability) and so much more! Please take a moment to see who we are!",
  reverse: false,
  image: "images/tokyo-temple.jpg",
  alt: "tokyo",
  link: "/team",
  buttontext: "Meet the team",
};
