import { Link } from "react-router-dom";

function CardItem({ path, src, alt, title, text, color, textcolor }) {
  return (
    <div className="carditem">
      <li className="cards__item">
        {path && (
          <Link className="cards__item__link" to={path}>
            <figure className="cards__item__pic-wrap" style={{ margin: 0 }}>
              <img className="cards__item__img link" alt={alt} src={src} />
            </figure>
            <div
              className="cards__item__info"
              style={{ backgroundColor: `${color}` }}
            >
              <p
                className="cards__item__header"
                style={{ color: { textcolor } }}
              >
                {title}
              </p>
              <p
                className="cards__item__text"
                style={{ color: `${textcolor}` }}
              >
                {text}
              </p>
            </div>
          </Link>
        )}
        {!path && (
          <div>
            <figure className="cards__item__pic-wrap">
              <img className="cards__item__img" alt={alt} src={src} />
            </figure>
            <div
              className="cards__item__info"
              style={{ backgroundColor: `${color}` }}
            >
              <p
                className="cards__item__header"
                style={{ color: { textcolor } }}
              >
                {title}
              </p>
              <p
                className="cards__item__text"
                style={{ color: `${textcolor}` }}
              >
                {text}
              </p>
            </div>
          </div>
        )}
      </li>
    </div>
  );
}

export default CardItem;
