import Button from "../Button";
import Gallery from "../Gallery";
import SmallCard from "../SmallCard";
import "./Tours.css";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import slides from "../data/CustomSlides.js";

function Custom() {
  return (
    <>
      <h1 className="section-heading">Customized Tours</h1>
      <div className="tourinfo-container">
        <p>
          Most of you coming to Japan already have a plan, a spreadsheet, or a
          social media page of activities you wish to see and do, how about
          sharing those with us and letting us help you curate your very own
          experience? We know that it can be challenging to organise and travel
          around a new country, so let us help you on your adventure and make it
          much easier! (Free zoom chat!)
        </p>
        <Link to="/contact">
          <Button text="Enquire Now" />
        </Link>
      </div>
      <div className="itinerary-container">
        <h1 className="section-heading" style={{ marginBottom: "2rem" }}>
          Example Tours
        </h1>
        <Container fluid="md">
          <Row className="mb-5" xs={1} sm={1} md={1} lg={3}>
            <Col className="d-flex justify-content-center">
              <SmallCard
                className="mb-5"
                imgSrc="/images/downtown/IMG_0311.jpg"
                title="Introductory Tour of Tokyo"
                text="First time in Tokyo? Not sure how to get around, or where to go? We can help you introduce how to get around Tokyo, easy tips, some recommendations and a lot more!"
              />
            </Col>
            <Col className="d-flex justify-content-center">
              <SmallCard
                className="mb-5"
                imgSrc="/images/custom/IMG_4374.jpg"
                title="2-3 hour tour of Tokyo Highlight"
                text="We will begin by explaining to you how best to get around Tokyo using public transport and online apps, your guide can recommend some places to visit, or feel free to pick a few areas according to your interests. "
                width="18rem"
              />
            </Col>
            <Col className="d-flex justify-content-center">
              <SmallCard
                className="mb-5"
                imgSrc="/images/custom/df0586ee-9ddb-4dcb-8596-ed67179ca4c9.jpg"
                title="Half-day/Full-day of Tokyo Tour"
                text="Leave it to us to guide you around or create your dream tour! You can choose the transportation most suitable for you, public transport, taxi or walking!"
                width="18rem"
              />
            </Col>
            <Col className="d-flex justify-content-center">
              <SmallCard
                className="mb-5"
                imgSrc="/images/custom/IMG_5447.jpg"
                title="Tour of Japan"
                text="We can take you on your once-in-a-lifetime Japan adventure. Where would you like to go? Any locations you've always dreamed of?"
              />
            </Col>
          </Row>
        </Container>
      </div>
      <Gallery slides={slides} />
    </>
  );
}
export default Custom;
