import { useState } from "react";
import { FaPlusCircle, FaMinusCircle } from "react-icons/fa";

function ReadMore({ text }) {
  const [isShowMore, setIsShowMore] = useState(false);

  const toggleReadMoreLess = () => {
    setIsShowMore(!isShowMore);
  };

  return (
    <div>
      {isShowMore && <p className="info-text">{text}</p>}
      <button
        onClick={toggleReadMoreLess}
        style={{ border: "none", background: "none", padding: 0 }}
      >
        {isShowMore ? (
          <FaMinusCircle style={{ color: "rgb(9, 59, 34)" }} />
        ) : (
          <FaPlusCircle style={{ color: "rgb(9, 59, 34)" }} />
        )}
      </button>
    </div>
  );
}

export default ReadMore;
