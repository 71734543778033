import "./ContactSection.css";

function ContactInfoItem(props) {
  return (
    <div className="contact">
      <div className="icon">{props.icon}</div>
      <div className="info">
        <p>{props.text}</p>
      </div>
    </div>
  );
}

export default ContactInfoItem;
