import { Modal, Image } from "react-bootstrap";
import { useState } from "react";

function Popup({ text, linkText, image, alt }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <p>
        {text}
        <a
          href="/#"
          onClick={(e) => {
            e.preventDefault();
            handleShow();
          }}
          style={{ color: "black", fontWeight: "bold" }}
        >
          {linkText}
        </a>
        .
      </p>
      <Modal size="lg" centered="true" show={show} onHide={handleClose}>
        <Modal.Body>
          <Image src={image} alt={alt} fluid />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Popup;
