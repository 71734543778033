import { OfferData } from "./data/OfferData.js";
import { Fade } from "react-awesome-reveal";
import ReadMore from "./ReadMore.js";
import Popup from "./Popup.js";

import "./Offer.css";

function Offer() {
  const offerList = OfferData.map((offer) => {
    return (
      <div className="offer">
        <img src={offer.image} alt={offer.alt} />
        <h1>{offer.title}</h1>
        <ReadMore text={offer.text} />
      </div>
    );
  });

  return (
    <div className="offer-section">
      <div className="offer-intro">
        <Fade direction="up" triggerOnce>
          <h1 className="section-heading">What we offer</h1>
        </Fade>
        <div className="mx-3 mx-lg-5 mb-2">
          <Fade triggerOnce>
            <Popup
              text="The Hanamichi team already has an established experience with touring Japan. We know the hustle and bustle of the city streets, nature walks and trails within bamboo forests as well as mountainous sceneries, incredible architecture, cultural experiences and seasonal activities. We have an understanding and knowledge of both the history and tradition of the country and look forward to sharing this with you. The team are able to offer guided day tours curated by the team or detailed itineraries to meet your individual needs. For an example of an itinerary please click "
              linkText="HERE"
              image="/images/itinerary.jpg"
              alt="sample itinerary"
            />
          </Fade>
        </div>
      </div>
      <div className="offers mx-3 mx-lg-5">
        <Fade
          cascade
          direction="up"
          duration={500}
          damping={0.2}
          delay={400}
          triggerOnce
        >
          {offerList}
        </Fade>
      </div>
    </div>
  );
}

export default Offer;
