const breakpoints = [4320, 2160, 1080, 640, 384, 256, 128];

const photoList = [
  {
    src: "/images/custom/89ab4d84-73e6-4051-a2d6-d8bc6e2d9b39.jpg",
    width: 3072,
    height: 4096,
  },
  {
    src: "/images/custom/df0586ee-9ddb-4dcb-8596-ed67179ca4c9.jpg",
    width: 2304,
    height: 4096,
  },
  {
    src: "/images/custom/IMG_0664.jpg",
    width: 3024,
    height: 4032,
  },
  {
    src: "/images/custom/IMG_0975.jpg",
    width: 3024,
    height: 4032,
  },
  {
    src: "/images/custom/IMG_1369.jpg",
    width: 4032,
    height: 3024,
  },
  {
    src: "/images/custom/IMG_2059.jpg",
    width: 4032,
    height: 3024,
  },
  {
    src: "/images/custom/IMG_4951.jpg",
    width: 4032,
    height: 3024,
  },
  {
    src: "/images/custom/IMG_4952.jpg",
    width: 3024,
    height: 4032,
  },
];

export const slides = photoList.map((photo) => {
  const width = photo.width * 4;
  const height = photo.height * 4;
  return {
    src: photo.src,
    width,
    height,
    // srcSet: breakpoints.map((breakpoint) => {
    //   const breakpointHeight = Math.round((height / width) * breakpoint);
    //   return {
    //     src: photo.src,
    //     width: breakpoint,
    //     height: breakpointHeight,
    //   };
    // }),
  };
});

export default slides;
