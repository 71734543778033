import Banner from "../Banner";
import Button from "../Button";
import Gallery from "../Gallery.js";
import Popup from "../Popup";
import "./Tours.css";

import slides from "../data/DowntownSlides.js";

function Sumida() {
  return (
    <>
      <h1 className="section-heading">Downtown Tokyo Tour</h1>
      <div className="tourinfo-container">
        <p>
          Enjoy a guided tour around a Japanese traditional working town, try
          delicious snacks, drink matcha lattes and shop for traditional
          hand-made items in the local merchant shops.
        </p>
        <Button text="Enquire now" />
      </div>
      <div className="tour-container">
        <Banner title="Neighborhood Tour" image="/images/downtown/IMG_0311.jpg">
          <p>
            Visit Sumida city, famous for the local craft merchants, and witness
            the incredible Japanese traditional hand-made items at Sumida Machi
            Dokoro. These are some of the traditional essential items dating
            back to the Edo period that make fantastic gifts and souveniers,
            including folding screens, Edo Katabami (handbags), Edo-style soy
            sauce containers, and much more.
          </p>
        </Banner>
        <Banner
          title="Sushi Experience | Shitamachi (downtown) Tour"
          image="/images/downtown/IMG_5042.JPG"
          right
        >
          <Popup
            text="We offer lunch at a very traditional local sushi restaurant, and talk to the sushi chef! He can explain how sushi can be made, and answer your questions you may have. Let’s walk around the local neighbourhood and see a traditional Japanese garden and lots more! For more details, please see "
            linkText="HERE"
            image="/images/Fukagawa.png"
            alt="sushi tour"
          />
        </Banner>
        <Banner
          title="Bon Odori Dance Lesson"
          image="/images/downtown/IMG_0308.jpg"
        >
          <p>
            Hanamichi has teamed up with a local dance teacher Miwako-san. This
            incredible opportunity allows you to learn the Bon Odori (Bon
            Dance), a summertime movement that celebrates a century old Buddhist
            custom called Obon, remembering the souls of the deceased. Dance to
            the taiko drums, shamisen and wooden flutes whilst dressed in a
            traditional kimono.
          </p>
        </Banner>
      </div>
      <Gallery slides={slides} />
    </>
  );
}
export default Sumida;
