import HeroSection from "../HeroSection";
import Cards from "../Cards.js";
import InfoSection from "../InfoSection.js";
import Offer from "../Offer.js";
import { InfoData } from "../data/InfoData.js";
import { Fade } from "react-awesome-reveal";
import BeholdWidget from "@behold/react";
import "../../App.css";
import "./Home.css";

function Home() {
  const mainText = `Hanamichi tour members pride themselves on their knowledge of Japan and its local communities. We aim to "blend in" rather than "stand out" to foreign tourists.\n\nOur guests can experience a wide variety of cultural and memorable tours whilst ensuring we do not disturb too much of the local community.`;

  return (
    <>
      <HeroSection
        image={"images/tokyo.jpg"}
        text={"Hanamichi Tours"}
        buttontext={"See Tours"}
      />
      <div className="container mt-5">
        <Fade triggerOnce>
          <h1 className="section-heading">Why Hanamichi?</h1>
        </Fade>
        <Fade direction="up" triggerOnce duration={500}>
          <p className="main-text" style={{ whiteSpace: "pre-wrap" }}>
            {mainText}
          </p>
        </Fade>
      </div>
      <Offer />
      <Cards id="tours" />
      <InfoSection {...InfoData} />
      <div className="instagram-widget mb-5">
        <h1 className="section-heading">Follow us on Instagram</h1>
        <div className="instagram-posts mx-3 my-3 mx-lg-5">
          <BeholdWidget feedId="RNEzNncis60Ux4GwLras" />
        </div>
      </div>
    </>
  );
}

export default Home;
