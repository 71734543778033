import { Card as BootstrapCard } from "react-bootstrap";
import "./SmallCard.css";

function SmallCard(props) {
  const { imgSrc, title, text, width, children, ...otherProps } = props;

  return (
    <BootstrapCard style={{ width: { width }, height: "auto" }} {...otherProps}>
      {imgSrc && (
        <BootstrapCard.Img
          className="team-card-image"
          variant="top"
          src={imgSrc}
        />
      )}
      <BootstrapCard.Body>
        {title && <BootstrapCard.Title>{title}</BootstrapCard.Title>}
        {text && <BootstrapCard.Text>{text}</BootstrapCard.Text>}
        {children}
      </BootstrapCard.Body>
    </BootstrapCard>
  );
}

export default SmallCard;
