import ContactInfoItem from "./ContactInfoItem";
import ContactForm from "./ContactForm";
import "./ContactSection.css";
import { FaPhone, FaEnvelope, FaFax } from "react-icons/fa";

function ContactSection() {
  return (
    <div className="container">
      <h1>Contact Us</h1>
      <div className="contactsection--wrapper mt-2 mt-lg-3">
        <div className="left">
          <ContactInfoItem
            icon={<FaPhone />}
            text="＋81-3-6661-7911"
          ></ContactInfoItem>
          <ContactInfoItem
            icon={<FaFax />}
            text="＋81-3-6661-7910 (Fax)"
          ></ContactInfoItem>
          <ContactInfoItem
            icon={<FaEnvelope />}
            text="info@hanamichi-tours.jp"
          ></ContactInfoItem>
          {/* <ContactInfoItem
            icon={<FaLocationArrow />}
            text="xxxxxxxxxx"
          ></ContactInfoItem> */}
        </div>
        <div className="right">
          <ContactForm />
        </div>
      </div>
    </div>
  );
}

export default ContactSection;
