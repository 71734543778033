import { Link } from "react-router-dom";
import { Fade, Slide } from "react-awesome-reveal";
import Button from "./Button.js";
import "./InfoSection.css";

const InfoSection = ({
  reverse,
  heading,
  paragraph,
  image,
  alt,
  link,
  buttontext,
}) => {
  return (
    <div className="info-section">
      <div className="info-container">
        <div className="column-left" style={{ order: { reverse } ? "2" : "1" }}>
          <Fade delay={200} duration={500} triggerOnce>
            <h1 className="section-heading">{heading}</h1>
          </Fade>
          <Fade delay={400} duration={500} triggerOnce>
            <div className="section-text">
              <p className="info-text" style={{ whiteSpace: "pre-wrap" }}>
                {paragraph}
              </p>
            </div>
          </Fade>
          <Fade delay={200} duration={500} triggerOnce>
            {link && (
              <div className="info-button">
                <Link to={link}>
                  <Button text={buttontext} />
                </Link>
              </div>
            )}
          </Fade>
        </div>
        <div
          className="column-right"
          style={{ order: { reverse } ? "1" : "2" }}
        >
          <Slide direction="left" triggerOnce>
            <img className="info-image" src={image} alt={alt} />
          </Slide>
        </div>
      </div>
    </div>
  );
};

export default InfoSection;
