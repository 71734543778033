import { Image, Container, Row, Col, Table } from "react-bootstrap";

function Company() {
  return (
    <>
      <Container>
        <Row>
          <Col></Col>
          <Col>
            <Image
              src="images/office-11-high-resolution-logo-transparent.png"
              fluid
            />
          </Col>
          <Col></Col>
        </Row>
      </Container>
      <h1 className="section-heading jp">会社概要</h1>
      <div className="mx-3 mx-lg-5 my-3">
        <Table className="jp" striped="columns" bordered>
          <tbody>
            <tr>
              <td style={{ display: "none" }}></td>
              <td>会社名</td>
              <td>株式会社OFFICE11 </td>
            </tr>
            <tr>
              <td style={{ display: "none" }}></td>
              <td>設立年月</td>
              <td>2015年5月</td>
            </tr>
            <tr>
              <td style={{ display: "none" }}></td>
              <td>代表取締役</td>
              <td>西澤　寛</td>
            </tr>
            <tr>
              <td style={{ display: "none" }}></td>
              <td>事業内容</td>
              <td>
                <ul>
                  <li>不動産コンサル（宅地建物取引士：西澤　寛）</li>
                  <li>コンサルティング業務</li>
                  <li>通訳・翻訳業務</li>
                  <li>旅行サービス手配業　東京都知事登録　第20638号</li>
                  <li>Webデザイン・製作業務</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  );
}

export default Company;
